/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  font-weight: 400;
  src: local('Source Sans Pro'), url('./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  font-weight: 600;
  src: local('Source Sans Pro'), url('./assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  font-weight: 700;
  src: local('Source Sans Pro'), url('./assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Zilla Slab';
  font-display: swap;
  font-weight: 400;
  src: local('Zilla Slab'), url('./assets/fonts/Zilla_Slab/ZillaSlab-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Zilla Slab';
  font-display: swap;
  font-weight: 600;
  src: local('Zilla Slab'), url('./assets/fonts/Zilla_Slab/ZillaSlab-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Zilla Slab';
  font-display: swap;
  font-weight: 700;
  src: local('Zilla Slab'), url('./assets/fonts/Zilla_Slab/ZillaSlab-Bold.ttf') format('truetype');
}

@tailwind base;
@layer base {
  :root {
    /* Global UI colors */
    --surface: #eeeef6;
    --surface-rise: #ffffff;
    --surface-yellow: #FFA600;
    --border: #d8d3de;
    --text: #3D255A;
    --interactive: #6328eb;
    --interactive-hover: #3d255a;
    --focus: var(--interactive);
    --active: var(--interactive);
    --space-x: 0.75rem;

    @media screen(lg) {
      --space-x: 1.5rem;
    }
  }

  /* Typography */
  h1 {
    @apply text-4xl lg:text-5xl leading-tight font-serif font-semibold mb-5;
  }

  h2 {
    @apply text-2xl lg:text-3xl font-serif font-semibold mb-4 mt-8;
  }

  h2:first-child {
    @apply mt-0;
  }

  h3 {
    @apply text-lg lg:text-xl font-bold mb-3 mt-6;
  }

  p {
    @apply max-w-xl;
    @apply mb-2;
  }

  /* UI */
  html,
  body,
  .loginView {
    @apply bg-ui-surface;
    @apply font-sans text-ui-foreground;
  }

  section {
    @apply my-6;
  }

  .__full-star {
    color: var(--text) !important;
  }

  .__empty-star {
    color: var(--border) !important;
  }

}

/* Dark Mode UI colors */
.dark {
  --surface: #110f13;
  --surface-rise: #27232b;
  --border: #4d4953;
  --text: #ececf1;
  --interactive: #A17DF3;
  --interactive-hover: #D0BEF9;
  --focus: var(--interactive);
  --active: var(--interactive);
}

@tailwind components;
@tailwind utilities;
